











































































































































































































































































































































































































































































































import Vue from 'vue'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormInput,
  BFormRadio,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import { tinyOptions } from '@core/mixins/tinyOptions'
import { flatPickerConfig } from '@core/mixins/flatPickerConfig'
import Editor from '@tinymce/tinymce-vue'
import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import AvailablePlaceholder from '@/views/components/available-placeholder/AvailablePlaceholder.vue'
import { appCommunicator } from '@/store/modules/app-communicator'
import { reportForSelect } from '@/types'
import transformReportFieldLabel from '@/helpers/transformReportFieldLabel'
import { permissionSubjects } from '@/libs/acl/constants'

export default Vue.extend({
  name: 'CommunicationAdd',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BFormCheckbox,
    flatPickr,
    Editor,
    vSelect,
    AvailablePlaceholder,
  },
  directives: {
    Ripple,
  },
  mixins: [tinyOptions, flatPickerConfig],
  data() {
    return {
      required,
      email,
    }
  },
  computed: {
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
    communication() {
      return appCommunicator.getters.getCommunication
    },
    communicationTypeOptions() {
      return appCommunicator.getters.getCommunicationTypeOptions
    },
    allAudienceTypesList() {
      return appCommunicator.getters.getAllAudienceTypesList
    },
    allGroupsList() {
      return appCommunicator.getters.getAllGroupsList
    },
    allSessionsList() {
      return appCommunicator.getters.getAllSessionsList
    },
    allReportsList() {
      return appCommunicator.getters.getAllReportsList
    },
    allReportFieldsList() {
      return appCommunicator.getters.getAllReportFieldsList
    },
    allReportPlaceholdersList() {
      return appCommunicator.getters.getAllReportPlaceholdersList
    },
    allUserRoles() {
      return appCommunicator.getters.getAllUserRolest
    },
    allEmailTemplatesList() {
      return appCommunicator.getters.getAllEmailTemplatesList
    },
    allSmsTemplatesList() {
      return appCommunicator.getters.getAllSmsTemplatesList
    },
    allLetterTemplatesList() {
      return appCommunicator.getters.getAllLetterTemplatesList
    },
    fieldGroupStatus() {
      return appCommunicator.getters.getFieldGroupStatus
    },
    fieldSessionStatus() {
      return appCommunicator.getters.getFieldSessionStatus
    },
    fieldReportStatus() {
      return appCommunicator.getters.getFieldReportStatus
    },
    fieldTypeStatus() {
      return appCommunicator.getters.getFieldTypeStatus
    },
    fieldTemplateStatus() {
      return appCommunicator.getters.getFieldTemplateStatus
    },
    fieldLetterTemplateStatus() {
      return appCommunicator.getters.getFieldLetterTemplateStatus
    },
    isLoading() {
      return appCommunicator.getters.getIsLoading
    },
    lastCursorPosition() {
      return appCommunicator.getters.getLastCursorPosition
    },
    isSmsType() {
      return appCommunicator.getters.getIsSmsType
    },
    allRecurringTypesList() {
      return appCommunicator.getters.getAllRecurringTypesList
    },
  },
  watch: {
    programId() {
      this.fetchGroups()
      this.fetchSessions()
    },
  },
  async created() {
    this.initCommunicationData()

    await this.fetchGroups()
    await this.fetchSessions()
    await this.fetchReports()
    await this.fetchCommunicationTypes()

    await appCommunicator.actions.fetchEmailTemplatesList()
    await appCommunicator.actions.fetchSmsTemplatesList()
    await appCommunicator.actions.fetchLetterTemplatesList()
  },
  methods: {
    initCommunicationData() {
      appCommunicator.mutations.SET_COMMUNICATION({
        content: '',
        audienceType: null,
        group: null,
        session: [],
        report: null,
        reportField: null,
        role: 'all',
        communicationType: null,
        emailTemplate: null,
        smsTemplate: null,
        letterTemplate: null,
        fromName: '',
        fromEmail: '',
        schedule: 'now',
        sendAt: null,
        subject: '',
        recurring: null,
        one_email_per_family: false,
      })
    },
    async fetchGroups() {
      const queryParams = {
        programId: this.programId,
      }
      appCommunicator.actions.fetchGroups(queryParams)
    },
    async fetchSessions() {
      const queryParams = {
        programId: this.programId,
      }
      appCommunicator.actions.fetchSessions(queryParams)
    },
    async fetchReports() {
      appCommunicator.actions.fetchReportsList()
    },
    async fetchCommunicationTypes() {
      const response = await appCommunicator.actions.fetchCommunicationTypes()
      const typeOptions = response.data.data.map((item: any) => {
        if (item.name === 'sms') {
          // eslint-disable-next-line no-param-reassign
          item.active = this.$can('send', permissionSubjects.BulkSMSCommunication)
        }
        if (item.name === 'email') {
          // eslint-disable-next-line no-param-reassign
          item.active = this.$can('send', permissionSubjects.BulkEmailCommunication)
        }
        return item
      })
      appCommunicator.mutations.SET_COMMUNICATION_TYPE_OPTIONS(typeOptions)
      appCommunicator.mutations.SET_FIELD_TYPE_STATUS('Select')
    },
    handleForm() {
      return new Promise((resolve, reject) => {
        (this.$refs.settingsRules as InstanceType<typeof ValidationObserver>).validate().then(success => {
          if (success) {
            resolve(true)
            this.sendMassCommunication()
          } else {
            window.scrollTo(0, 0)
            reject()
          }
        })
      })
    },
    async sendMassCommunication() {
      await appCommunicator.actions.sendMassCommunication()
    },
    resetForm() {
      this.initCommunicationData()
    },
    selectedEmailTemplate() {
      this.communication.content = this.communication.emailTemplate?.body || ''
      this.communication.subject = this.communication.emailTemplate?.subject || ''
    },
    selectedSmsTemplate() {
      this.communication.content = this.communication.smsTemplate?.body || ''
    },
    insertValueToEditor(val: any) {
      if (this.isSmsType) {
        const result = this.communication.content.slice(0, this.lastCursorPosition) + val + this.communication.content.slice(this.lastCursorPosition)
        this.communication.content = result
      } else {
        getTinymce().activeEditor.execCommand('mceInsertContent', false, val)
      }
    },
    setCursorPosition(event: any) {
      appCommunicator.mutations.SET_LAST_CURSOR_POSITION(event.target.selectionEnd)
    },
    updateReportFields(report: reportForSelect) {
      this.communication.reportField = null
      const reportFields = report.selected_fields.reduce((fields, item) => {
        fields.push({
          label: transformReportFieldLabel(item),
          value: item,
        })
        return fields
      }, [] as {label: string, value: string}[])
      appCommunicator.mutations.SET_ALL_REPORT_FIELDS_LIST(reportFields)

      const reportPlaceholders = report.selected_fields.reduce((fields, item) => {
        fields.push({
          type: 'fixed',
          value: `{{_${item}}}`,
          label: item,
        })
        return fields
      }, [] as {type: string, label: string, value: string}[])
      appCommunicator.mutations.SET_ALL_REPORT_PLACEHOLDERS_LIST(reportPlaceholders)
    },
  },
})
