import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import store from '@/store'
import { CommunicatorState } from './types'
import { ICommunication, ICommunicationRequest } from '@/views/admin/communication/communication-add/types'
import { camperStudent } from '@/helpers/instanceNamingConvention'
import { fetchGroupsForSelect } from '@/api/groups'
import { fetchSessionsForSelect } from '@/api/sessions'
import { editScheduledEmail, fetchCommunicationTypes, sendCommunication } from '@/api/comunications'
import { fetchEmailTemplatesList } from '@/api/emailTemplates'
import { fetchSmsTemplatesList } from '@/api/smsTemplates'
import { fetchLettersList } from '@/api/letterTemplates'
import { fetchReportsList } from '@/api/reports'

// eslint-disable-next-line import/prefer-default-export
export const appCommunicator = createModule(
  'appCommunicator',
  {
    namespaced: true,
    state: {
      communication: {} as ICommunication,
      communicationTypeOptions: [],
      allGroupsList: [],
      allSessionsList: [],
      allReportsList: [],
      allReportFieldsList: [],
      allReportPlaceholdersList: [],
      allAudienceTypesList: [
        {
          label: 'Group',
          value: 'group',
        },
        {
          label: 'Report',
          value: 'report',
        },
      ],
      allRecurringTypesList: [
        {
          label: 'Weekly',
          value: 'weekly',
        },
        {
          label: 'Biweekly (twice a month)',
          value: 'biweekly',
        },
        {
          label: 'Monthly',
          value: 'monthly',
        },
      ],
      allUserRoles: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Admin',
          value: 'admin',
        },
        {
          label: 'Staff',
          value: 'staff',
        },
        {
          label: 'Parent',
          value: 'parent',
        },
        {
          label: camperStudent,
          value: camperStudent.toLocaleLowerCase(),
        },
      ],
      allEmailTemplatesList: [],
      allSmsTemplatesList: [],
      allLetterTemplatesList: [],
      fieldGroupStatus: 'Loading...',
      fieldSessionStatus: 'Loading...',
      fieldReportStatus: 'Loading...',
      fieldTypeStatus: 'Loading...',
      fieldTemplateStatus: 'Loading...',
      fieldLetterTemplateStatus: 'Loading...',
      isLoading: false,
      lastCursorPosition: 0,
    } as CommunicatorState,
    getters: {
      getCommunication: state => state.communication,
      getCommunicationTypeOptions: state => state.communicationTypeOptions,
      getAllAudienceTypesList: state => state.allAudienceTypesList,
      getAllGroupsList: state => state.allGroupsList,
      getAllSessionsList: state => state.allSessionsList,
      getAllReportsList: state => state.allReportsList,
      getAllReportFieldsList: state => state.allReportFieldsList,
      getAllReportPlaceholdersList: state => state.allReportPlaceholdersList,
      getAllUserRolest: state => state.allUserRoles,
      getAllEmailTemplatesList: state => state.allEmailTemplatesList,
      getAllSmsTemplatesList: state => state.allSmsTemplatesList,
      getAllLetterTemplatesList: state => state.allLetterTemplatesList,
      getFieldGroupStatus: state => state.fieldGroupStatus,
      getFieldSessionStatus: state => state.fieldSessionStatus,
      getFieldReportStatus: state => state.fieldReportStatus,
      getFieldTypeStatus: state => state.fieldTypeStatus,
      getFieldTemplateStatus: state => state.fieldTemplateStatus,
      getFieldLetterTemplateStatus: state => state.fieldLetterTemplateStatus,
      getIsLoading: state => state.isLoading,
      getLastCursorPosition: state => state.lastCursorPosition,
      getIsSmsType: state => state.communication.communicationType && state.communication.communicationType?.name === 'sms',
      getAllRecurringTypesList: state => state.allRecurringTypesList,
    },
    mutations: {
      SET_COMMUNICATION(state, val: ICommunication) {
        state.communication = val
      },
      SET_COMMUNICATION_TYPE_OPTIONS(state, val) {
        state.communicationTypeOptions = val
      },
      SET_ALL_GROUPS_LIST(state, val) {
        state.allGroupsList = val
      },
      SET_ALL_SESSIONS_LIST(state, val) {
        state.allSessionsList = val
      },
      SET_ALL_REPORTS_LIST(state, val) {
        state.allReportsList = val
      },
      SET_ALL_REPORT_FIELDS_LIST(state, val) {
        state.allReportFieldsList = val
      },
      SET_ALL_REPORT_PLACEHOLDERS_LIST(state, val) {
        state.allReportPlaceholdersList = val
      },
      SET_ALL_EMAIL_TEMPLATES_LIST(state, val) {
        state.allEmailTemplatesList = val
      },
      SET_ALL_SMS_TEMPLATES_LIST(state, val) {
        state.allSmsTemplatesList = val
      },
      SET_ALL_LETTER_TEMPLATES_LIST(state, val) {
        state.allLetterTemplatesList = val
      },
      SET_FIELD_GROUP_STATUS(state, val) {
        state.fieldGroupStatus = val
      },
      SET_FIELD_SESSION_STATUS(state, val) {
        state.fieldSessionStatus = val
      },
      SET_FIELD_REPORT_STATUS(state, val) {
        state.fieldReportStatus = val
      },
      SET_FIELD_TYPE_STATUS(state, val) {
        state.fieldTypeStatus = val
      },
      SET_FIELD_TEMPLATE_STATUS(state, val) {
        state.fieldTemplateStatus = val
      },
      SET_FIELD_LETTER_TEMPLATE_STATUS(state, val) {
        state.fieldLetterTemplateStatus = val
      },
      SET_IS_LOADING(state, val) {
        state.isLoading = val
      },
      SET_LAST_CURSOR_POSITION(state, val) {
        state.lastCursorPosition = val
      },
    },
    actions: {
      async fetchGroups({ state }, queryParams?: any) {
        appCommunicator.mutations.SET_FIELD_GROUP_STATUS('Loading...')
        state.communication.group = null

        const response = await fetchGroupsForSelect(queryParams)

        appCommunicator.mutations.SET_ALL_GROUPS_LIST(response.data.data)
        appCommunicator.mutations.SET_FIELD_GROUP_STATUS('Select')
      },
      async fetchSessions({ state }, queryParams?: any) {
        appCommunicator.mutations.SET_FIELD_SESSION_STATUS('Loading...')
        state.communication.session = null

        const response = await fetchSessionsForSelect(queryParams)

        appCommunicator.mutations.SET_ALL_SESSIONS_LIST(response.data.data)
        appCommunicator.mutations.SET_FIELD_SESSION_STATUS('Select')
      },
      async fetchCommunicationTypes() {
        const response = await fetchCommunicationTypes()
        return response
      },
      async fetchReportsList() {
        const response = await fetchReportsList()

        appCommunicator.mutations.SET_ALL_REPORTS_LIST(response.data.data)
        appCommunicator.mutations.SET_FIELD_REPORT_STATUS('Select')
      },
      async fetchEmailTemplatesList() {
        const response = await fetchEmailTemplatesList()

        appCommunicator.mutations.SET_ALL_EMAIL_TEMPLATES_LIST(response.data.data)
        appCommunicator.mutations.SET_FIELD_TEMPLATE_STATUS('Select')
      },
      async fetchSmsTemplatesList() {
        const response = await fetchSmsTemplatesList()

        appCommunicator.mutations.SET_ALL_SMS_TEMPLATES_LIST(response.data.data)
        appCommunicator.mutations.SET_FIELD_TEMPLATE_STATUS('Select')
      },
      async fetchLetterTemplatesList() {
        const response = await fetchLettersList()

        appCommunicator.mutations.SET_ALL_LETTER_TEMPLATES_LIST(response.data.data)
        appCommunicator.mutations.SET_FIELD_LETTER_TEMPLATE_STATUS('Select')
      },
      async sendMassCommunication({ state, rootGetters }) {
        try {
          const formData: ICommunicationRequest = {
            message: state.communication.content,
            audience_type: state.communication.audienceType,
            group_id: state.communication.group?.id,
            session_id: state.communication.session?.map(session => session.id),
            report_id: state.communication.report?.id,
            report_field: state.communication.reportField,
            role: state.communication.role,
            communication_type_id: state.communication.communicationType?.id,
            from_name: state.communication.fromName,
            from_email: state.communication.fromEmail,
            subject: state.communication.subject,
            letter_template: state.communication.letterTemplate,
            email_template: state.communication.emailTemplate?.id,
            recurring_type: state.communication.recurring,
            program_id: rootGetters['verticalMenu/getDefaultProgram'],
            one_email_per_family: state.communication.one_email_per_family,
          }
          if (state.communication.schedule === 'later') {
            formData.send_at = state.communication.sendAt
          }
          appCommunicator.mutations.SET_IS_LOADING(true)
          await sendCommunication(formData)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Communication was successfully send',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error sending communication',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appCommunicator.mutations.SET_IS_LOADING(false)
        }
      },
      async editScheduledEmail({ state }) {
        try {
          const formData: ICommunicationRequest = {
            id: state.communication.id,
            message: state.communication.content,
            audience_type: state.communication.audienceType,
            group_id: state.communication.group?.id,
            report_id: state.communication.report?.id,
            report_field: state.communication.reportField,
            role: state.communication.role,
            communication_type_id: state.communication.communicationType?.id,
            from_name: state.communication.fromName,
            from_email: state.communication.fromEmail,
            subject: state.communication.subject,
            letter_template: state.communication.letterTemplate,
            email_template: state.communication.emailTemplate?.id,
            recurring_type: state.communication.recurring,
          }
          if (state.communication.schedule === 'later') {
            formData.send_at = state.communication.sendAt
          }

          appCommunicator.mutations.SET_IS_LOADING(true)

          await editScheduledEmail(formData)

          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success updating scheduled email',
              icon: 'Trash2Icon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error canceling scheduled email',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appCommunicator.mutations.SET_IS_LOADING(false)
        }
      },
    },
  },
)

appCommunicator.register(store)
